import React, { useEffect, useState } from 'react';


const Language = (props) => {
    const [isClicked, setIsClicked] = useState(false);

    const openLanguage = () => {
        if (isClicked == false) {
            document.querySelector("#select-language").hidden = false;
            setIsClicked(true);
            if (props.language == "eng") {
                document.querySelector("#select-language").value="eng";
            } else {
                document.querySelector("#select-language").value="fr";
            }
        } else {
            document.querySelector("#select-language").hidden = true;
            setIsClicked(false);
        }
    }

    const changeLanguage = (e) => {
        document.querySelector("#select-language").hidden = true;
        setIsClicked(false);
        props.changeLanguage(e.target.value);
    }

    return (
        <div id="language-part">
            <div id="language-and-text">
            <svg id="svg-language" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 31 29.1" onClick={() => openLanguage()}>
                <g id="language-line" transform="translate(-2 -3)">
                    <path id="Tracé_4" data-name="Tracé 4" d="M30,3H14V8h2V5H30a.945.945,0,0,1,1,1V17a.945.945,0,0,1-1,1H17v7H11.7L8,27.9V25H5a.945.945,0,0,1-1-1V13a.945.945,0,0,1,1-1H18V10H5a2.946,2.946,0,0,0-3,3V24a2.946,2.946,0,0,0,3,3H6v5.1L12.3,27H19V20H30a2.946,2.946,0,0,0,3-3V6A2.946,2.946,0,0,0,30,3Z" />
                    <path id="Tracé_5" data-name="Tracé 5" d="M6.2,22.9H8.6l.6-1.6h3.1l.6,1.6h2.4L11.9,14H9.5Zm4.5-6.4,1,3.1h-2Z" />
                    <path id="Tracé_6" data-name="Tracé 6" d="M20,17a9.4,9.4,0,0,0,4-1,9.746,9.746,0,0,0,4,1V15a6.852,6.852,0,0,1-2.1-.4A7.6,7.6,0,0,0,28,9V8H25V6H23V8H20v2h5.9A5.026,5.026,0,0,1,24,13.6a5.844,5.844,0,0,1-1.6-2.1H20.3a7.271,7.271,0,0,0,1.8,3.1A5.746,5.746,0,0,1,20,15v2Z" />
                </g>
            </svg>
            <div className="language-text font-12">{props.language == "fr" ? "Changer la langue" : "Change the language"}</div>
            </div>
            <select id="select-language" hidden onChange={(e) => changeLanguage(e)}>
                <option id="selected-french" value="fr">Français</option>
                <option id="selected-english" value="eng">English</option>
            </select>
        </div>

    );
}

export default Language;