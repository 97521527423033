import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import ProjetFilRougeOne from './ProjetFilRougeOne';
import ProjetFilRougeTwo from './ProjetFilRougeTwo';
import ProjetFilRougeThree from './ProjetFilRougeThree';
import ProjetFilRougeFour from './ProjetFilRougeFour';
import Internship from './Internship';

const Main = (props) => {

    return (
        <div className="content">
            <Header changeLanguage={props.changeLanguage} language={props.language} changeColor={props.changeColor} goTo={props.goTo} />
            <Home changeLanguage={props.changeLanguage} language={props.language} goTo={props.goTo} />
            <About changeLanguage={props.changeLanguage} language={props.language} />
            <ProjetFilRougeOne changeLanguage={props.changeLanguage} language={props.language} />
            <ProjetFilRougeTwo changeLanguage={props.changeLanguage} language={props.language} />
            <ProjetFilRougeThree changeLanguage={props.changeLanguage} language={props.language} />
            <ProjetFilRougeFour changeLanguage={props.changeLanguage} language={props.language} />
            <Internship changeLanguage={props.changeLanguage} language={props.language} />
            <Contact language={props.language} goTo={props.goTo} />
        </div>
    );
}
export default Main;