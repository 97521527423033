import React, { useEffect, useState } from 'react';
import Nav from './Nav';


const Header = (props) => {

    return (
        <Nav changeLanguage={props.changeLanguage} language={props.language} changeColor={props.changeColor} goTo={props.goTo}/>
    );
}

export default Header;