import React, { useEffect, useState } from 'react';


const Color = (props) => {
    const [isClicked, setIsClicked] = useState(false);
    const openColor = (e) => {
        if (isClicked == false) {
            document.querySelector(".dots").hidden = false;
            setIsClicked(true);
        } else {
            document.querySelector(".dots").hidden = true;
            setIsClicked(false);
        }
    }

    const changeColor = (e) => {
        props.changeColor(e);
        document.querySelector(".dots").hidden = true;
        setIsClicked(false);
    }

    return (
        <div id="color-selector">
            <div id="dot-and-text">
                <div className="only-dot"><span className="dot" onClick={() => openColor()}><p>C</p></span></div>
                <div className="dot-text font-12">{props.language == "fr" ? "Changer la couleur" : "Change the color"}</div>
            </div>
            <div className="dots" hidden>
                <span className="dot1" onClick={() => changeColor(1)}><p>C</p></span>
                <span className="dot2" onClick={() => changeColor(2)}><p>C</p></span>
                <span className="dot3" onClick={() => changeColor(3)}><p>C</p></span>
                <span className="dot4" onClick={() => changeColor(4)}><p>C</p></span>
            </div>
        </div>

    );
}

export default Color;