import React, { useEffect, useState } from 'react';
import pfr3 from '../../assets/pictures/pfr3.png'
import Fade from 'react-reveal/Fade'

const ProjetFilRougeThree = (props) => {

    return (
        <div id="pfr-three-page">
            <div id="pfr-three-page-title">Projet Fil Rouge</div>
            <div id="pfr-three-page-content">
                <div id="pfr-three-page-left">
                    <div id="pfr-three-page-text">
                        <p>3/ {props.language == "fr" ? "Application Web e-commerce" : "Web e-commerce app"}</p>
                        <p>{props.language == "fr" ? "L'application, développée sur le logiciel " : "The app, developped with the software "}
                            <span className="highlight bold">Visual Studio Code</span> {props.language == "fr" ? "et codée en " : "and coded in "}  
                            <span className="highlight bold">PHP </span>
                            {props.language == "fr" ? "avec utilisation du " : "using the "} Framework
                            <span className="highlight bold"> CodeIgniter</span> {props.language == "fr" ? "et du moteur de templating " : "and the template engine "}
                            <span className="highlight bold">Twig</span>.
                            {props.language == "fr" ? " L'application permet de proposer un ensemble de recettes, créées"+
                            " au préalable dans la partie administrative, ainsi qu'un certain nombre"+
                            " d'articles à la vente. Un module sous forme de Sweep & Keep a été mis"+
                            " en place afin de proposer des recettes aux utilisateurs en fonction"+
                            " d'ingrédients sélectionnés" : 
                            " The app displays recipes, created before one the administrative part, and a number of article to sell. " +
                            "A Sweep & Keep module has been implemented to suggest recipes to the user according to selected ingredients"}.
                            </p>
                    </div>
                </div>
                {/* <Fade bottom> */}
                    <img id="pfr-three-page-right" src={pfr3} alt="Screenshot of desktop application" />
                {/* </Fade> */}
            </div>
        </div>
    );
}

export default ProjetFilRougeThree;