import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './components/pages/Page';

const App = (props) => {

  const [language, setLanguage] = useState(JSON.parse(localStorage.getItem("selectedLanguage")) != null ? JSON.parse(localStorage.getItem("selectedLanguage")) : "fr");
  const [scroll, _setScroll] = useState(0);
  const scrollRef = useRef(scroll);
  const setScroll = data => {
    scrollRef.current = data;
    _setScroll(data);
  }
  var startTouch = 0;

  useEffect(() => {
    localStorage.getItem("color") != null ? changeColor(Number(localStorage.getItem("color"))) : changeColor(1);
    document.addEventListener("keydown", (e) => keyTouch(e));
    document.addEventListener("touchstart", (e) => touchStart(e));
    document.addEventListener("touchend", (e) => touchMove(e));
    document.addEventListener("wheel", (e) => handleWheel(e));
    document.getElementById("root").style.transition = "330ms ease-in-out";
  }, []);

  const changeLanguage = (value) => {
    setLanguage(value);
    localStorage.setItem("selectedLanguage", JSON.stringify(value));
  }

  const changeColor = (value) => {
    switch (value) {
      case 1:
        document.documentElement.style.setProperty('--highlightColor', "#fd5555");
        document.documentElement.style.setProperty('--backgroundColor', "#F8F9FA");
        document.documentElement.style.setProperty('--textColor', "black");
        document.documentElement.style.setProperty('--filter', "invert(60%) sepia(49%) saturate(7262%) hue-rotate(331deg) brightness(103%) contrast(97%)");
        localStorage.setItem("color", 1);
        break;
      case 2:
        document.documentElement.style.setProperty('--highlightColor', "#dfe04e");
        document.documentElement.style.setProperty('--backgroundColor', "#012844");
        document.documentElement.style.setProperty('--textColor', "white");
        document.documentElement.style.setProperty('--filter', "invert(82%) sepia(27%) saturate(932%) hue-rotate(12deg) brightness(105%) contrast(89%)");
        localStorage.setItem("color", 2);
        break;
      case 3:
        document.documentElement.style.setProperty('--highlightColor', "#002394");
        document.documentElement.style.setProperty('--backgroundColor', "#c4989b");
        document.documentElement.style.setProperty('--textColor', "black");
        document.documentElement.style.setProperty('--filter', "invert(12%) sepia(84%) saturate(3604%) hue-rotate(224deg) brightness(87%) contrast(112%)");
        localStorage.setItem("color", 3);
        break;
      case 4:
        document.documentElement.style.setProperty('--highlightColor', "#bb7151");
        document.documentElement.style.setProperty('--backgroundColor', "#0d0d0d");
        document.documentElement.style.setProperty('--textColor', "white");
        document.documentElement.style.setProperty('--filter', "invert(47%) sepia(39%) saturate(609%) hue-rotate(333deg) brightness(96%) contrast(88%)");
        localStorage.setItem("color", 4);
        break;
      default:
        document.documentElement.style.setProperty('--highlightColor', "rgba(255, 0, 0, 0.658)");
        document.documentElement.style.setProperty('--backgroundColor', "#F8F9FA");
        document.documentElement.style.setProperty('--textColor', "black");
        document.documentElement.style.setProperty('--filter', "invert(60%) sepia(49%) saturate(7262%) hue-rotate(331deg) brightness(103%) contrast(97%)");
        localStorage.setItem("color", 1);
        break;
    }
  }

  const handleWheel = (e) => {
    setTimeout(function () {
      var delta = Math.sign(e.deltaY);
      var position = 0;
      var key = e.keyCode;
      if (delta > 0 || key == 40) {
        position = scrollRef.current + window.innerHeight;
        if (scrollRef.current < window.innerHeight * 7) {
          document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
          setScroll(position);
        } else {
          setScroll(window.innerHeight * 7);
        }
      } else if (delta < 0 || key == 38) {
        position = scrollRef.current - window.innerHeight;
        if (scrollRef.current >= window.innerHeight) {
          document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
          setScroll(position);
        } else {
          setScroll(0);
        }
      }
    }, 100);
  }

  const goTo = (value) => {
    var position = (Number(value) * window.innerHeight);
    document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
    setScroll(position);
  }

  const touchStart = (e) => {
    startTouch = e.touches[0].pageY;
  }


  const [size, setSize] = useState(10)

  const touchMove = (e) => {
    var end = e.changedTouches[0].pageY;
    var deltaY = end - startTouch;
    var position = 0;
    if (deltaY < -20) {
      position = scrollRef.current + window.innerHeight;
      if (scrollRef.current < window.innerHeight * 7) {
        document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
        setScroll(position);
      } else {
        setScroll(window.innerHeight * 7);
      }
    } else if (deltaY > 20) {
      position = scrollRef.current - window.innerHeight;
      if (scrollRef.current >= window.innerHeight) {
        document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
        setScroll(position);
      } else {
        setScroll(0);
      }
    }
  }

  const keyTouch = (e) => {
    var key = e.keyCode;
    handleKey(key);
  }

  const handleKey = (value) => {
    var position = 0;
    if (value == 40) {
      position = scrollRef.current + window.innerHeight;
      if (scrollRef.current < window.innerHeight * 7) {
        document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
        setScroll(position);
      } else {
        setScroll(window.innerHeight * 7);
      }
    } else if (value == 38) {
      position = scrollRef.current - window.innerHeight;
      if (scrollRef.current >= window.innerHeight) {
        document.getElementById("root").style.transform = "translate(0,-" + Number(position) + "px)";
        setScroll(position);
      } else {
        setScroll(0);
      }
    }
  }

  return (
    <Router>
      <Route exact path="/" component={() => <Main path="/" changeLanguage={changeLanguage} language={language} changeColor={changeColor} goTo={goTo} />} />
    </Router>
  );
}

export default App;