import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import cv from '../../assets/cv.pdf'

const About = (props) => {

    return (
        <div id="about-page">
            <div id="about-page-title">{props.language == "fr" ? "A propos" : "About"}</div>
            <div id="about-page-content">
                <div id="about-page-left">
                    <div id="about-page-left-text">
                        <p>{props.language == "fr" ? "Salut, je suis" : "Hi, I'm"} <span className="about-name highlight delayed">Thibault</span>.</p>
                        <p> <span className="highlight bold">{props.language == "fr" ? "Ingénieur en travaux publics" : "Civil Engineer"}</span>
                            {props.language == "fr" ? "  de formation, j'ai décidé après 3 années à travailler dans le milieu, de me" : "  at first, I have decided after 3 years working in this field, to"}
                            <span className="highlight bold">{props.language == "fr" ? " réorienter" : " retrain myself"}</span>
                            {props.language == "fr" ? " dans la conception et le développement d'applications informatiques." : " in the design and development of IT applications"}
                        </p>
                        <p>{props.language == "fr" ? "J'ai ainsi suivi une formation de " : "So, I took an "}
                            <span className="highlight bold">{props.language == "fr" ? " Concepteur Développeur d'Applications" : " App developper and designer"}</span>
                            {props.language == "fr" ? " (Titre Professionnel de niveau 6, bac +3) au sein du Greta de Montpellier (34)" :
                                " classe (Bachelor level Title) at Montpellier (France) Greta"}.
                        </p>
                        <p>{props.language == "fr" ? "Avant de réaliser cette formation, j'ai effectué une année de " : " Before doing this classe, I've carried out a year of "}
                            <span className="highlight bold">WHV </span>
                            (Working Holiday Visa) {props.language == "fr" ? " en" : " in"}
                            <span className="highlight bold"> {props.language == "fr" ? " Australie" : " Australia"}</span>
                            {props.language == "fr" ? ", afin dans un premier temps d'améliorer mon anglais, ainsi que de partir à la découverte de nouveaux horizons." :
                                ", in order to improve my english and discover new horizons."}
                        </p>
                        <p>
                            <a href="https://github.com/Tibo30" target="_blank">
                                <FontAwesomeIcon icon={faGithub} className="logo-github" />
                                Github
                            </a>
                            <a href="https://www.linkedin.com/in/thibault-jolivet-0931579b/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedinIn} className="logo-linkedin-about" />
                                Linkedin
                            </a>
                            <a href={cv} target="_blank">
                                <FontAwesomeIcon icon={faFilePdf} className="logo-cv-about" />
                                CV
                            </a>
                        </p>
                    </div>
                </div>
                <div id="about-page-right">
                    <div id="about-page-right-text">
                        <div id="about-page-language">
                            <FontAwesomeIcon icon={faCode} className="logo" />
                            <p><span className="highlight bold">{props.language == "fr" ? "Langages" : "Languages"}</span></p>
                            <p>HTML</p>
                            <p>CSS</p>
                            <p>PHP</p>
                            <p>Java</p>
                            <p>JavaScript</p>
                            <p>React Js ({props.language == "fr" ? "notions" : "basic knowledge"})</p>
                            <p>Node Js ({props.language == "fr" ? "notions" : "basic knowledge"})</p>
                        </div>
                        <div id="about-page-tools">
                            <FontAwesomeIcon icon={faLaptop} className="logo" />
                            <p><span className="highlight bold">{props.language == "fr" ? "Outils" : "Tools"}</span></p>
                            <p>Eclipse</p>
                            <p>Visual Studio Code</p>
                            <p>Android Studio</p>
                            <p>Github</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;