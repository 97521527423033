import React, { useEffect, useState } from 'react';
import Language from './Language';
import Color from './Color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/pictures/logo.png'

const Nav = (props) => {

    const [isSmall, setSmall] = useState(window.innerWidth < 660);
    const [isClicked, setIsClicked] = useState(false);

    const updateMedia = () => {
        setSmall(window.innerWidth < 660);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
    });

    const responsiveNavbar = () => {
        var links = document.querySelectorAll(".navbar-link");
        if (isClicked == false) {
            links.forEach(element => {
                element.hidden = false;
            });
            setIsClicked(true);
        } else {
            links.forEach(element => {
                element.hidden = true;
            });
            setIsClicked(false);
        }
    }

    return (
        <div className="navbar">
            <img id="logo-web" src={logo}></img>
            <div className={`${isSmall == true ? "navbar-part1-bis" : "navbar-part1"}`}>
                {isSmall == true && <a><FontAwesomeIcon icon={faBars} className="logo" onClick={() => responsiveNavbar()} /></a>}
                <a className="navbar-link" onClick={() => props.goTo(1)} hidden={isSmall == false ? false : true}><div>{props.language == "fr" ? "A propos" : "About"}</div></a>
                <a className="navbar-link" onClick={() => props.goTo(2)} hidden={isSmall == false ? false : true}><div>{props.language == "fr" ? "Projets" : "Projects"}</div></a>
                <a className="navbar-link" onClick={() => props.goTo(7)} hidden={isSmall == false ? false : true}><div>{props.language == "fr" ? "Contact" : "Contact"}</div></a>
            </div>
            <div className="navbar-part2">
                <Language changeLanguage={props.changeLanguage} language={props.language} />
                <Color changeColor={props.changeColor} language={props.language} />
            </div>
        </div>
    );
}

export default Nav;