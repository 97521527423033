import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {

    //tabIndex="0" onKeyDown= {(e)=>props.handleKey(e.keyCode)}
    return (
        <div id="home-page">
            <div id="home-text">
                <div id="home-name">Thibault JOLIVET</div>
                <div id="home-title" className="anim-typewriter">{props.language == "fr" ? "Concepteur Développeur d'Applications" : "App developper and designer"}</div>
            </div>
            <div id="home-picture">
            </div>
            <div id="home-scroll" onClick={() => props.goTo(1)}>
                Scroll
                <FontAwesomeIcon icon={faArrowAltCircleDown} className="logo" />
            </div>
        </div>
    );
}

export default Home;