import React, { useEffect, useState } from 'react';
import pfr1 from '../../assets/pictures/pfr1.png'
import Fade from 'react-reveal/Fade'

const ProjetFilRougeOne = (props) => {

    return (
        <div id="pfr-one-page">
            <div id="projects-page-title">{props.language == "fr" ? "Projets" : "Projects"}</div>
            <div id="pfr-one-page-title">Projet Fil Rouge</div>
            <div id="pfr-one-page-content">
                <div id="pfr-one-page-left">
                    <div id="pfr-one-page-text">
                        <p>1/ {props.language == "fr" ? "Application de gestion de stock de " : "Stock management and "}
                            <span className="highlight bold">{props.language == "fr" ? "type desktop" : "desktop like "}</span>{props.language == "fr" ? "" : "application"}</p>
                        <p>{props.language == "fr" ? "L'application, développée sur le logiciel" : "The app, developped with the software "}
                            <span className="highlight bold"> ECLIPSE</span> {props.language == "fr" ? "et codée en" : "and coded in "}
                            <span className="highlight bold"> JAVA</span>,
                            {props.language == "fr" ? " permet de gérer les produits (ingrédients ou ustensiles), les articles, " +
                                "les commandes et les fournisseurs de la société. L'interface graphique a été réalisée avec la bibliothèque" :
                                " allows the user to manage the products (ingredients or utensils), articles, orders and suppliers of the company."
                                + " The graphicale interface has been made with the library"}

                            <span className="highlight bold"> Swing</span>.</p>
                    </div>
                </div>
                {/* <Fade bottom> */}
                    <img id="pfr-one-page-right" src={pfr1} alt="Screenshot of desktop application" />
                {/* </Fade> */}
            </div>
        </div>
    );
}

export default ProjetFilRougeOne;