import React, { useEffect, useState } from 'react';
import pfr2 from '../../assets/pictures/pfr2.png'
import Fade from 'react-reveal/Fade'

const ProjetFilRougeTwo = (props) => {

    return (
        <div id="pfr-two-page">
            <div id="pfr-two-page-title">Projet Fil Rouge</div>
            <div id="pfr-two-page-content">
                {/* <Fade bottom> */}
                    <img id="pfr-two-page-left" src={pfr2} alt="Screenshot of desktop application" />
                {/* </Fade> */}
                <div id="pfr-two-page-right">
                    <div id="pfr-two-page-text">
                        <p>2/ {props.language == "fr" ? "Application Web administrative" : "Web administrative app"}</p>
                        <p>{props.language == "fr" ? "L'application a été développée sur le logiciel " : "The app has been developped with the software "}
                        <span className="highlight bold">Visual Studio Code</span> {props.language == "fr" ? "et codée en " : "and coded in "}  
                        <span className="highlight bold">PHP</span>.
                        {props.language == "fr" ? " Elle permet à l'entreprise de gérer ses recettes, articles et utilisateurs,"+
                            " ainsi que de visualiser les statistiques importantes" : 
                            " This one allows the company to manage its recipes, articles and users, as well as the essential statistics"}.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjetFilRougeTwo;