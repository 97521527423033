import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';
import Footer from '../footer/Footer';


const Contact = (props) => {

    return (
        <div id="contact-page">
            <div id="contact-page-background">
                <ul id="contact-page-background-list">
                    <li>{props.language == "fr" ? "Vous" : "Want"}</li>
                    <li>{props.language == "fr" ? "voulez en" : "to know"}</li>
                    <li>{props.language == "fr" ? "savoir plus" : "more"} ?</li>
                </ul>
            </div>
            <div id="contact-page-title">Contact</div>
            <div id="contact-page-content">
                <p>{props.language == "fr" ? "Pour de plus amples informations, vous pouvez me contacter directement par " :
                    "For more information, you can reach me directly by "}
                    <a href="mailto:thibault.jolivet30@laposte.net"><span className="highlight">Email</span> <FontAwesomeIcon icon={faEnvelope} className="logo-email" /></a></p>
                <p>{props.language == "fr" ? "Ou m'envoyer un message sur mon profil  " : "Or send me a message on my "}
                    <a href="https://www.linkedin.com/in/thibault-jolivet-0931579b/" target="_blank">
                        <span className="highlight">Linkedin </span>
                        <FontAwesomeIcon icon={faLinkedinIn} className="logo-linkedin" /></a></p>
            </div>
            <div id="contact-page-up">
                <FontAwesomeIcon icon={faHandPointer} className="logo" onClick={() => props.goTo(0)} />
                <div className="highlight">To the top</div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;