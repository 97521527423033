import React, { useEffect, useState } from 'react';

const Internship = (props) => {

    return (
        <div id="internship-page">
            <div id="internship-page-title">{props.language == "fr" ? "Stage" : "Internship"}</div>
            <div id="internship-page-content">
                <div id="internship-page-left">
                    <div id="internship-page-left-text">
                        <p>{props.language == "fr" ? "Un " : "A "}
                            <span className="highlight bold">{props.language == "fr" ? "stage " : " ten-week internship "}</span>
                            {props.language == "fr" ? "d'une période de " : ""}
                            <span className="highlight bold">{props.language == "fr" ? "10 semaines " : ""}</span>
                            {props.language == "fr" ? "en distanciel a été réalisé auprès de la société Digigom, située à POLIGNAC (43)." +
                                " La société DIGIGOM a créé une plateforme permettant aux garagistes d’obtenir" +
                                " les meilleurs prix sur leurs pneus. Travaillant avec de nombreux garagistes, DIGIGOM s’est rendu compte du peu" +
                                " de connaissances ou originalité en matière de site web." +
                                " De ce fait, un projet de template modulable de site e-commerce « clé en main »" +
                                ", codé en " :
                                "in full remote has been carried out with the company DIGIGOM, located in POLIGNAC (France)." +
                                " DIGIGOM has created a platform allowing the mechanics to get the best prices on tyres. Working with loads of" +
                                " mechanics, DIGIGOM realized the lack of knowledge or originality on their web sites." +
                                " Consequently, a project of an e-commerce, adjustable and ready for use template, coded in "}
                            <span className="highlight bold"> React Js</span> / <span className="highlight bold"> Node Js</span>
                            {props.language == "fr" ? " a été effectué" : " has been made"}.

                        </p>
                    </div>
                </div>
                <div id="internship-page-right">
                    <div id="internship-page-right-text">
                        <p>{props.language == "fr" ? "La maquette de l'application a été réalisée en collaboration avec une stagiaire en graphisme/design" :
                            "The app mock-up has been made with the collaboration of a design intern"}
                            .</p>
                        <p>{props.language == "fr" ? "La partie " : "The "}<span className="highlight bold">Front-End</span>
                            {props.language == "fr" ? " de l'application a été codée en " : " part of the app has been coded in "}
                            <span className="highlight bold">React Js</span>
                            {props.language == "fr" ? ". Les données stockées sur le Système de Gestion de Base de Données (SGBD) " :
                                ". The data stored in the DataBase Management System (DBMS) "}
                            <span className="highlight bold">MongoDB</span>
                            {props.language == "fr" ? " ont été récupérées via le package " : " have been retrieved with the package "}
                            <span className="highlight bold">Axios</span>.</p>
                        <p>{props.language == "fr" ? "La partie " : "The "} <span className="highlight bold">Back-End</span>
                            {props.language == "fr" ? " de l'application a été codée en " : " part of the app has been coded in "}
                            <span className="highlight bold">Node Js</span>
                            {props.language == "fr" ? " avec utilisation des modules suivants : " : " with the use of the following module : "}
                        </p>
                        <ul>
                            <li>Express : {props.language == "fr" ? "Framework standard pour le développement de serveur en Node Js." :
                                "Standard Framework for the development of server in Node Js."}</li>
                            <li>Mongoose : {props.language == "fr" ? "Module permettant de définir des objets pour travailler dans un environnement asynchrone avec le SGBD MongoDB." :
                                "Module allowing the define objects to work in an asynchronous environment with the DBMS MongoDB."}
                            </li>
                            <li>Cors : {props.language == "fr" ? "Module permettant d'activer le Cross Origin Resource Sharing (CORS)." +
                                " Cela permet d'accéder à des ressources restreintes situées sur une autre origine que le site courant." :
                                "Module allowing to activate the Cross Origin Resource Sharing (CORS)." +
                                " That allows to reach restricted resources located on another origin than the current website."}
                            </li>
                            <li>Body-parser : {props.language == "fr" ? "Ce module permet notamment d'extraire et analyser les données JSON ou URL envoyées lors d'une requête." :
                                "This module allows to extract and analyze JSON data or URL sent during a request."}
                            </li>
                        </ul>
                        <p>{props.language == "fr" ? "Une " : "An "} <span className="highlight bold">API Rest</span>
                            {props.language == "fr" ? " a donc été réalisée afin de pouvoir envoyer et récupérer les données nécessaires au bon fonctionnement de l'application." :
                                " has been made to be able to send and get data required for the smooth operation of the app."}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Internship;