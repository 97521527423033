import React, { useEffect, useState } from 'react';
import Github from './Github';
import Linkedin from './Linkedin'

const Footer = (props) => {

    return (
        <div id="footer-content">
            <div id="footer-text">2021 © All rights reserved | Created and Designed by Thibault Jolivet</div>
        </div>

    );
}

export default Footer;