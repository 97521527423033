import React, { useEffect, useState } from 'react';
import pfr4 from '../../assets/pictures/pfr4.png'
import Fade from 'react-reveal/Fade'

const ProjetFilRougeFour = (props) => {

    return (
        <div id="pfr-four-page">
            <div id="pfr-four-page-title">Projet Fil Rouge</div>
            <div id="pfr-four-page-content">
                {/* <Fade bottom> */}
                    <img id="pfr-four-page-left" src={pfr4} alt="Screenshot of desktop application" />
                {/* </Fade> */}
                <div id="pfr-four-page-right">
                    <div id="pfr-four-page-text">
                        <p>4/ {props.language == "fr" ? "Application " : ""} <span className="highlight bold">{props.language == "fr" ? "mobile " : "Mobile"}</span>{props.language == "fr" ?  "" : " app"}</p>
                        <p>{props.language == "fr" ? "L'application a été développée sur le logiciel " : "The app has been developped with the software"}
                            <span className="highlight bold"> Android Studio </span>{props.language == "fr" ? "et codée en " : "and coded in "}  
                            <span className="highlight bold">JAVA</span>.
                            {props.language == "fr" ? " L'application permet aux utilisateurs d'avoir accès à leurs recettes préférées"+
                            " ainsi qu'à une liste interactive de courses." : 
                            " The app allows the users to follow up their favourite recipes sorted by categories and offer an interactive shopping list."}  
                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjetFilRougeFour;